import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { API_URL } from '../../api/axios';

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const handleScroll = (index) => () => {
    let newIndex = index;
    if (index < 0) {
      newIndex = images.length - 1;
    } else if (index > images.length - 1) {
      newIndex = 0;
    }
    carouselRef.current.scrollLeft = carouselRef.current.clientWidth * newIndex;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="py-20-xxl py-12 container px-6-lg px-2 w-90-lg w-full mx-auto">
      <div className="flex items-center w-full relative">
        <button
          className="rounded bg-blue-400 p-4 border-0 relative-lg absolute z-10"
          onClick={handleScroll(currentIndex - 1)}
        >
          <StaticImage
            quality={100}
            src="../../images/icons/left-carousel-arrow.svg"
            width={24}
            height={24}
            objectFit="contain"
            alt="Left carousel arrow"
          />
        </button>
        <div
          className="w-75-lg w-80 mx-auto overflow-auto no-scrollbar flex items-center snap-mandatory smooth-scroll"
          ref={carouselRef}
        >
          {images?.map((image) => (
            <div
              key={image.id}
              className="overflow-hidden min-w-full snap-align-start h-300-px-mobile"
            >
              <img
                src={`${API_URL}${image?.attributes?.url}`}
                height={520}
                className="w-full min-w-full object-cover max-h-520-px"
              />
            </div>
          ))}
        </div>
        <button
          className="rounded bg-blue-400 p-4 border-0 relative-lg absolute z-10 right-0"
          onClick={handleScroll(currentIndex + 1)}
        >
          <StaticImage
            quality={100}
            src="../../images/icons/right-carousel-arrow.svg"
            width={24}
            height={24}
            objectFit="contain"
            alt="Right carousel arrow"
          />
        </button>
      </div>
    </div>
  );
};

Carousel.propTypes = {
  images: PropTypes.array,
};

export default Carousel;
