import React from 'react';
import PropTypes from 'prop-types';

const SharedHero = ({ title, children }) => {
  return (
    <div className="pt-218-px-lg pt-100-px relative">
      <div className="py-20-xxl py-12 w-90-lg px-6 w-full mx-auto">
        {children}
        <div className=" w-full max-w-1400 mx-auto pb-12">
          <h1 className="font-bold text-5xl-xxl text-4xl text-white text-center max-w-1000-xl max-w-700 mx-auto mb-12 w-50-lg">
            {title}
          </h1>
        </div>
      </div>
    </div>
  );
};

SharedHero.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default SharedHero;
