import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../../shared/PageLayout';
import Hero from '../../page-components/referencePage/Hero';
import InfoCard from '../../page-components/referencePage/InforCard';
import Description from '../../page-components/referencePage/Description';
import Carousel from '../../page-components/referencePage/Carousel';
import OtherReferences from '../../page-components/referencePage/OtherReferences';
import CTASection from '../../page-components/homePage/CTA';
import axios from '../../api/axios';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

const ReferencePage = ({ serverData }) => {
  const { referenceData, solutions } = serverData;
  const name = referenceData?.attributes?.name ?? '';
  const { t } = useTranslation();
  const { generalInfo, projectName, projectDescription, year, interveners } = t(
    'referencePage',
    { returnObjects: true },
  );
  return (
    <PageLayout solutions={solutions}>
      <Hero
        title={name}
        cover={referenceData?.attributes?.cover?.data?.attributes?.url}
      />
      <InfoCard
        title={generalInfo}
        list={[
          {
            label: projectName,
            value: name,
          },
          {
            label: projectDescription,
            value: referenceData?.attributes?.description ?? [],
            isRichText: true,
          },
          {
            label: year,
            value: referenceData?.attributes?.year ?? '',
          },
        ]}
      />
      <Description {...referenceData?.attributes?.solution?.data?.attributes} />
      <InfoCard
        title={interveners}
        list={referenceData?.attributes?.interveners?.data?.map(
          (intervener) => ({
            label: intervener?.attributes?.label ?? '',
            value: intervener?.attributes?.value ?? '',
          }),
        )}
      />
      <Carousel images={referenceData?.attributes?.images?.data} />
      <OtherReferences otherReferences={[]} />
      <CTASection />
    </PageLayout>
  );
};

ReferencePage.propTypes = {
  serverData: PropTypes.object,
};

export default ReferencePage;

export async function getServerData({ params }) {
  const { slug } = params;
  try {
    const { data } = await axios.get('/api/references', {
      params: {
        filters: {
          slug: {
            $eq: slug,
          },
        },
        populate: {
          solution: {
            populate: {
              fields: ['name', 'description'],
              image: {
                fields: ['url'],
              },
              icon: {
                fields: ['url'],
              },
            },
          },
          interveners: {
            fields: ['label', 'value'],
          },
          images: {
            fields: ['url'],
          },
          cover: {
            fields: ['url'],
          },
          icon: {
            fields: ['url'],
          },
        },
      },
    });

    const { data: solutionData } = await axios.get('/api/solutions', {
      params: {
        fields: ['name', 'slug'],
        populate: {
          products: {
            fields: ['name', 'slug'],
            populate: {
              product_types: {
                fields: ['name', 'slug'],
                populate: {
                  product_items: {
                    fields: ['name', 'slug'],
                  },
                },
              },
            },
          },
        },
      },
    });

    return {
      props: {
        referenceData: data?.data[0],
        solutions: solutionData?.data.map((item) => ({
          title: item?.attributes?.name,
          url: item?.attributes?.slug,
          subMenu: item?.attributes?.products?.data?.map((subItem) => ({
            title: subItem?.attributes?.name,
            url: subItem?.attributes?.slug,
            subMenu: subItem?.attributes?.product_types?.data?.map(
              (subSubItem) => ({
                title: subSubItem?.attributes?.name,
                url: subSubItem?.attributes?.slug,
              }),
            ),
          })),
        })),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["References", "Layout"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
