// import React from 'react';
// import { StaticImage } from 'gatsby-plugin-image';
// import ReferenceCard from '../References/ReferenceCard';
// import Button from '../../shared/Buttons';

const OtherReferences = () => {
  return null;
  /* return (
    <div className="py-20-xxl py-12-lg relative overflow-hidden">
      <StaticImage
        quality={100}
        src="../../images/reference-page/reference-cover.jpg"
        layout="fullWidth"
        className="absolute top-0 left-0 w-full -z-10 h-300-px-mobile"
        objectPosition="50% 50%"
      />
      <div className="py-20-xxl py-12 container mx-auto flex flex-col w-90-lg w-full">
        <h2 className="font-bold text-5xl-xxl text-4xl text-white text-center mb-12-lg mb-20 underline-white relative">
          Autres références
        </h2>

        <div className="grid grid-cols-3-lg z-10 gap-16-xxl gap-10">
          {list.map((item) => (
            <ReferenceCard key={item.label} {...item} />
          ))}
        </div>
        <div className="mt-20-xl mt-16 text-center w-full flex justify-center">
          <Button withArrow>Voir tous les références</Button>
        </div>
      </div>
    </div>
  ); */
};

export default OtherReferences;
